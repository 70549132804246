import type { PageProps } from 'gatsby'
import React from 'react'
import EnterpriseFitnessLanding from 'enterprise/fitness/components/EnterpriseFitnessLanding'
import enterprise from 'libs/enterprise'

type Props = PageProps

export default function EnterprisePatina(props: Props) {
  return (
    <EnterpriseFitnessLanding
      {...props}
      partner={enterprise.PATINA_KEY}
      className="EnterprisePatina"
    />
  )
}
